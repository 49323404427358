<template>
  <div class="flex flex-col items-center pt-24">
    <h2 class="font-medium">Admin overview</h2>
    <router-link to="/admin/ingredients" class="bg-primary text-ternary rounded px-2 py-1 mt-2">Ingredients</router-link>
    <router-link to="/admin/recipes" class="bg-primary text-ternary rounded px-2 py-1 mt-2">Recipes</router-link>
    <router-link to="/admin/recipes/new" class="bg-primary text-ternary rounded px-2 py-1 mt-2">New Recipe</router-link>
    <div @click="logout" class="mt-2 hover:underline cursor-pointer">Logout</div>
  </div>
</template>

<script>
import { clearAuth } from '../../store'

export default {
  methods: {
    logout () {
      clearAuth()

      window.location.reload()
    }
  }
}
</script>
